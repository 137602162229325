import * as React from 'react';
import { 
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { 
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';

const formContainer = {
	width: '100%',
}

export default function PapInput(props) {
    const [values, setValues] = React.useState({
        showPassword: false,
      });

    const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

    return (
        <FormControl style={formContainer} variant="outlined">
            <InputLabel style={props.inputLabelStyle} htmlFor={props.id}>{props.label}</InputLabel>
            <OutlinedInput
                id={props.id}
                style={props.inputStyle}
                type={props.isPassword ? (values.showPassword ? 'text' : 'password') : 'text'}
                value={props.value}
                onChange={props.onChange}
                endAdornment={
					props.isPassword &&
					<InputAdornment position="end">
						<IconButton
						aria-label="toggle password visibility"
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
						edge="end"
						>
						{values.showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
                }
                label={props.label}
            />
        </FormControl>
    )
}