import * as React from 'react';
import { 
	Alert,
    Grid,
} from '@mui/material';

export default function PapAlert(props) {
    return (
        <Grid item xs={12} style={{position: 'fixed', margin: '25px', zIndex:1000}}>
            <Alert severity={props.severity}>{props.message}</Alert>
        </Grid>
    )
}